<template>
  <div class="tw-flex tw-justify-between md:tw-justify-start tw-items-center">
    <CurrentWeatherDetails
      :class-names="currentDetailsClassNames"
      :forecast-current="forecastCurrent"
    />
    <CurrentWindDetails
      :class-names="currentWindDetailsClassNames"
      :forecast-current="forecastCurrent"
    />
  </div>
</template>

<script>
export default {
  name: 'CurrentConditions',

  props: {
    currentDetailsClassNames: {
      type: String,
      default: 'lg:tw-w-1/3 xl:tw-w-1/5',
    },
    forecastCurrent: {
      type: Object,
      default: null,
    },
  },

  computed: {
    currentWindDetailsClassNames() {
      return `${this.currentDetailsClassNames} tw-pl-2`;
    },
  },
};
</script>
