<template>
  <CurrentDetails
    :class="classNames"
    :icon="icon"
    :text="text"
  >
    <template #subText>
      <span v-if="isCalm">Calm</span>
      <span v-else>
        {{ windDirLabel }}
        gusts to
        <strong :style="windGustSpeedStyle">{{ windGustSpeed }}</strong>
        {{ windUnits }}
      </span>
    </template>
  </CurrentDetails>
</template>

<script>
import { mapState } from 'pinia';
import {
  formatWind,
  getAlertColorPrimary,
  getWindUnits,
} from '@@/utils/CommonUtils';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';

export default {
  name: 'CurrentWindDetails',

  props: {
    classNames: {
      type: String,
      default: null,
    },
    forecastCurrent: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(useMetaStore, [
      'getWindAlert',
      'windForecastAlertIcon',
    ]),

    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),

    icon() {
      return 'https://blizzard.opensnow.com/icons/wx/day/wind.png';
    },

    isCalm() {
      return this.windGustSpeed === 'Calm';
    },

    text() {
      return formatWind(this.forecastCurrent.wind_speed, this.units, true);
    },

    windAlert() {
      return this.getWindAlert(this.forecastCurrent.alerts);
    },

    windDirLabel() {
      return this.forecastCurrent?.wind_dir_label || '';
    },

    windGustSpeed() {
      return formatWind(this.forecastCurrent.wind_gust_speed, this.units, false);
    },

    windGustSpeedStyle() {
      if (this.windAlert) {
        return {
          color: getAlertColorPrimary(this.windAlert),
        };
      }

      return undefined;
    },

    windUnits() {
      return getWindUnits(this.units);
    },
  },
};
</script>
