<template>
  <div>
    <div class="tw-flex tw-justify-start tw-items-center">
      <img
        :class="iconClass"
        :src="icon"
      >
      <strong :class="textClass">
        {{ text }}
      </strong>
    </div>
    <small :class="subTextClass">
      <slot name="subText" />
    </small>
  </div>
</template>

<script>
export default {
  name: 'CurrentDetails',

  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },

  computed: {
    iconClass() {
      return [
        'tw-inline-block',
        'tw-h-8 tw-w-8',
        'tw-mr-0.5 lg:tw-mr-1',
        'dark-invert-100%',
      ];
    },

    subTextClass() {
      return [
        'tw-block',
        'tw-text-xs lg:tw-text-sm',
        'tw-whitespace-nowrap',
        'text-dark-color',
      ];
    },

    textClass() {
      return [
        'tw-block',
        'tw-text-xl lg:tw-text-2xl tw-leading-6 lg:tw-leading-8',
      ];
    },
  },
};
</script>
