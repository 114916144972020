<template>
  <CurrentDetails
    :class="classNames"
    :icon="icon"
    :text="text"
  >
    <template #subText>
      {{ subText }}, feels like <strong :style="feelsLikeStyle">{{ feelsLike }}</strong>
    </template>
  </CurrentDetails>
</template>

<script>
import { mapState } from 'pinia';
import { formatTemp, getAlertColorPrimary } from '@@/utils/CommonUtils';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';

export default {
  name: 'CurrentWeatherDetails',

  props: {
    classNames: {
      type: String,
      default: null,
    },
    forecastCurrent: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(useMetaStore, [
      'getFeelsLikeAlert',
    ]),

    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),

    feelsLike() {
      return formatTemp(this.forecastCurrent.temp_apparent, this.units);
    },

    feelsLikeAlert() {
      return this.getFeelsLikeAlert(this.forecastCurrent.alerts);
    },

    feelsLikeStyle() {
      if (this.feelsLikeAlert) {
        return {
          color: getAlertColorPrimary(this.feelsLikeAlert),
        };
      }

      return undefined;
    },

    icon() {
      return this.forecastCurrent.conditions_icon_url;
    },

    subText() {
      return this.forecastCurrent.conditions_label;
    },

    text() {
      return formatTemp(this.forecastCurrent.temp, this.units, this.$config);
    },
  },
};
</script>
